<template>
  <div class="container my-24 my-lg-40">
    <div class="row">
      <div class="col-12 col-xl-3 order-xl-2 mb-24 sticky">
        <v-card class="overflow-hidden sticky">
          <l-widget>
            <template #header>
              <w-timer
                :start="widget.timer.start"
                :end="widget.timer.end"
                :server-time="widget.timer.current"
              />
            </template>

            <template #default>
              <w-progress
                :progress="widget.progress"
              />
            </template>
          </l-widget>
        </v-card>
      </div>

      <div class="col-12 col-xl-9 order-xl-1">
        <v-card class="py-32 px-16 p-xl-32">
          <div class="row">
            <div class="col">
              <p>
                В детском лагере вожатый проводит с детьми утреннюю зарядку, и вся группа постепенно просыпается.
                Команды ведущего выполняются, у детей повышается настроение. Неожиданно один мальчик громко заявляет:
                «Зачем мы все это делаем? Я хочу спать. Вы меня заставляете, вы нарушаете мои права». Детям около 15-16
                лет. Остальные прекратили выполнять упражнения и с интересом смотрят, как отреагирует вожатый, это
                определенный вызов. Просто продолжите нельзя — дети ждут от вожатого ответа.
              </p>

              <p>
                <b>
                  Распишите ваше мнение по этой ситуации.
                </b>
              </p>
            </div>
          </div>

          <div
            v-for="(textarea, i) in textareas"
            :key="i"
            class="row"
          >
            <div class="col">
              <v-field
                :error="!!textarea.error"
              >
                <label>{{ textarea.label }}</label>
                <v-textarea
                  v-model="textarea.value"
                  :required="textarea.required"
                  :placeholder="textarea.placeholder"
                  :autogrow="textarea.autogrow"
                ></v-textarea>
                <span class="v-field__help">{{ textarea.help }}</span>
                <span class="v-field__error">{{ textarea.error }}</span>
              </v-field>
            </div>
          </div>

          <div class="row mt-32">
            <div class="col">
              <v-button
                :disabled="isClickable"
                @click="checkFields"
              >
                Ответить
              </v-button>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  VButton,
  VCard,
  VField,
  VTextarea
} from '@components/base'
import { WTimer, WProgress } from '@components/widgets'
import { LWidget } from '@/layouts'

export default {
  name: 'STextarea',

  components: {
    LWidget,
    VButton,
    VCard,
    VField,
    VTextarea,
    WProgress,
    WTimer
  },

  data () {
    return {
      widget: {
        progress: {
          total: 3,
          current: 1
        },
        timer: {
          start: Math.floor(+new Date() / 1000),
          end: Math.floor(+new Date() / 1000) + 120
        }
      },
      textareas: [
        {
          value: '',
          help: 'c autogrow',
          autogrow: true,
          label: 'Введите ваш ответ',
          required: true
        },
        {
          value: '',
          placeholder: 'Введите ваше имя'
        }
      ]
    }
  },

  computed: {
    isClickable () {
      return !this.textareas.every(input => input.required ? !!input.value : true)
    }
  },

  methods: {
    checkFields () {
      this.textareas = this.textareas.map((input, i) => {
        if (input.required) {
          input.error = input.value.length < 3
            ? 'Поле должно содержать не менее 3х букв'
            : ''
        }

        return input
      })
    }
  }
}
</script>
